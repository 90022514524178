var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-vendor", {
                  attrs: {
                    type: "search",
                    name: "manuCompany",
                    label: "제조회사",
                  },
                  model: {
                    value: _vm.searchParam.manuCompany,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "manuCompany", $$v)
                    },
                    expression: "searchParam.manuCompany",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "예비품 목록",
            tableId: "equipmentInsp",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
            selection: "multiple",
            rowKey: "spareSeq",
          },
          on: { linkClick: _vm.linkClick },
        },
        [
          _c("template", { slot: "table-button" }, [
            _c(
              "div",
              [
                _c(
                  "q-btn-group",
                  [
                    _c("c-btn", {
                      attrs: {
                        label: "예비품 일괄 업로드",
                        editable: _vm.editable,
                        icon: "assignment",
                      },
                    }),
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: {
                            label: "추가",
                            showLoading: true,
                            editable: _vm.editable,
                            icon: "add",
                          },
                          on: { btnClicked: _vm.add },
                        })
                      : _vm._e(),
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: {
                            url: _vm.insertUrl,
                            isSubmit: _vm.isSave,
                            param: _vm.grid.data,
                            mappingType: "POST",
                            label: "저장",
                            icon: "save",
                          },
                          on: {
                            beforeAction: _vm.saveSparePart,
                            btnCallback: _vm.saveCallback,
                          },
                        })
                      : _vm._e(),
                    _c("c-btn", {
                      attrs: { label: "검색", icon: "search" },
                      on: { btnClicked: _vm.getList },
                    }),
                    _vm.editable && _vm.isDelete
                      ? _c("c-btn", {
                          attrs: {
                            label: "삭제",
                            showLoading: true,
                            editable: _vm.editable,
                            icon: "remove",
                          },
                          on: { btnClicked: _vm.remove },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }